import React, { useState, Fragment } from 'react';
import { Col, Row, Card, Table } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { PayPalButton } from './paypalButton';
import { productIDs } from '../../../javascript/App';
import { productSizesMap } from '../../../enums/productSizes';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';

export const Checkout = ({ cart, setCart, calculateTotal, calculateDiscount, notify }) => {
  const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;
  const [hasSuccessfulOrderPlaced, setHasSuccessfulOrderPlaced] = useState(false);
  const isSmall = useMediaQuery('(max-width:800px)');

  const removeItem = (itemID) => {
    const cartClone = { ...cart };
    const newItems = cartClone.Items.filter(x => x.ItemID !== itemID) || [];
    const discount = calculateDiscount(newItems);
    cartClone.Discount = discount;
    cartClone.Items = newItems;
    cartClone.Total = calculateTotal(cartClone.Items);
    setCart(cartClone);
  };


  const structuredData = {
    "@context": "http://schema.org/",
    "@type": "ItemList",
    "itemListElement": cart.Items.map((item, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Product",
        "name": item.Name,
        "description": item.Description,
        "sku": item.ProductID,
        "offers": {
          "@type": "Offer",
          "priceCurrency": "USD",
          "price": item.Price,
          "availability": "http://schema.org/InStock",
        }
      }
    }))
  };

  return (
    <div>
    <Helmet>
      <title>Checkout | Grand Carpet Mill</title>
      <meta name="description" content="Review your cart and complete your purchase using PayPal." />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
      <Card style={{ minHeight: '85vh' }}>
        <Card.Header>
          <Card.Title style={{ fontSize: '1.5rem' }}>
            Order Checkout
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {cart && !hasSuccessfulOrderPlaced && Boolean(cart.Items.length) ? (
            <Fragment>
              <Row>
                <Col md={{ span: 6, offset: 2 }}>
                  <h4>Thank you for your Order!</h4>
                </Col>
              </Row>
              <Row className="mx-auto" style={{ maxHeight: '40vh', overflowY: 'auto', marginTop: '4rem' }}>
                <Col md={{ span: 8, offset: 2 }}>
                  <Table responsive style={{ fontSize: isSmall ? '.9rem' : '1rem' }}>
                    <thead>
                      <tr>
                        <th>Quantity</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Shipping</th>
                        <th>Handling</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Boolean(cart.Items.length) && cart.Items.map(x => (
                        <tr key={x.ItemID}>
                          <td>{x.Quantity}</td>
                          <td>{`${x.Name} ${x.ProductID === productIDs.WorkingHarness ? `(${productSizesMap[x.SizeID]})` : ''}`}</td>
                          <td>{x.Description}</td>
                          <td>{moneyFormat(x.Price)}</td>
                          <td>{moneyFormat(x.Shipping)}</td>
                          <td>{moneyFormat(x.Handling)}</td>
                          <td>
                            <CancelIcon
                              style={{ cursor: 'pointer' }}
                              color={'error'}
                              onClick={() => removeItem(x.ItemID)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={{ span: 6, offset: 6 }}>
                  <div>
                    <Typography variant="body1" className="text-center" style={{ fontSize: '1.3rem', fontStyle: 'italic' }}>
                      Discount:<span>{` ${moneyFormat(cart.Discount)}`}</span>
                    </Typography>
                    <Typography variant="body1" className="text-center" style={{ fontSize: '1.3rem', fontStyle: 'italic' }}>
                      Grand Total:<span>{` ${moneyFormat(cart.Total)}`}</span>
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: '2rem' }}>
                <Col xl={{ span: 2, offset: 8 }} lg={{ span: 4, offset: 6 }} md={{ span: 4, offset: 6 }}>
                  <PayPalButton
                    cart={cart}
                    setCart={setCart}
                    setHasSuccessfulOrderPlaced={setHasSuccessfulOrderPlaced}
                  />
                </Col>
              </Row>
            </Fragment>
          ) : hasSuccessfulOrderPlaced ? (
            <CSSTransition
              in={hasSuccessfulOrderPlaced}
              timeout={300}
              classNames="order-placed"
              unmountOnExit
            >
              <div style={{ textAlign: 'center', marginTop: '20vh' }}>
                <CheckCircleIcon style={{ fill: 'green', fontSize: '4rem' }} />
                <span style={{ fontSize: '1.5rem' }}>Thank you for your order!</span>
              </div>
            </CSSTransition>
          ) : (
            <Row style={{ marginTop: '30vh' }}>
              <Col className="text-center">
                <h4>Your cart is empty.</h4>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Checkout;