import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Home from './UI/Home';
import { Products } from './UI/Products/products';
import FAQ from './UI/faq/FAQ';
import Testimonials from './UI/About/Testimonials';
import ContactUs from './UI/About/ContactUs';
import NavBar from './UI/NavBar';
import Footer from './UI/Footer';
import Checkout from './UI/order/checkout';
import MissionStatement from './UI/About/MissionStatement';
import { getItemTotal, getProductList } from '../javascript/App';
import { productIDs } from '../javascript/App';
import { Toasty } from './UI/toasty'
import initalCart from '../javascript/utility';
import { Helmet } from 'react-helmet';

const App = () => {
  const [cart, setCart] = useState(initalCart);
  const [itemId, setItemId] = useState(0);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    // May want to parse the cart here.
  }, []);

  const calculateTotal = (items) => {
    let price = 0;
    items.forEach(x => {
      price += getItemTotal(x.Price, x.Quantity, x.Shipping, x.Handling);
    });
    const discount = calculateDiscount(items);
    return (price - discount) || 0;
  };

  const calculateDiscount = (items) => {
    if (items) {
      const harnessDiscount = 6 + 16.95; // 6 dollars off and 16.95 off for shipping
      let numberOfHarnesses = 0;
      let numberOfTreadmills = 0;
      items.forEach(x => {
        if (x.IsTreadmill) {
          numberOfTreadmills = numberOfTreadmills + x.Quantity;
        }
        else if (x.ProductID === productIDs.WorkingHarness) {
          numberOfHarnesses = numberOfHarnesses + x.Quantity;
        }
      });
      let numberOfDiscounts = 0;
      if (numberOfHarnesses < numberOfTreadmills && numberOfTreadmills !== 0) {
        numberOfDiscounts = Math.ceil(numberOfHarnesses / numberOfTreadmills) * numberOfHarnesses;
      } else if (numberOfHarnesses !== 0) {
        numberOfDiscounts = Math.ceil(numberOfTreadmills / numberOfHarnesses) * numberOfTreadmills;
      }
      return (harnessDiscount * numberOfDiscounts) || 0;
    }
    return 0;
  };

  const notify = (title, msg) => {
    let tsts = toasts.map(x => x);
    const time = new Date();
    tsts.push({
      title: title,
      message: msg,
      key: time.toLocaleDateString()
    });
    setToasts(tsts);
    return tsts;
  };

  const productList = getProductList();
  return (
    <Fragment>
      <BrowserRouter>
        <Helmet>
          <title>Grand Carpet Mill</title>
          <meta name="description" content="Grand Carpet Mill offers premium, furniture-quality exercise treadmills for dogs to keep them healthy and active." />
        </Helmet>
        <Container fluid="lg">
          <div>          <NavBar cart={cart} />
            <Switch>
              <Route exact path="/Home" component={Home} />
              <Route
                path="/GrandDeluxe"
                render={() => (
                  <>
                    <Helmet>
                      <title>Grand Deluxe Treadmill | Grand Carpet Mill</title>
                      <meta name="description" content="Buy the Grand Deluxe Treadmill for your dog at Grand Carpet Mill. Premium quality for the best exercise experience." />
                    </Helmet>
                    <Products notify={notify} product={productList.GrandDeluxe} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/BigDog"
                render={() => (
                  <>
                    <Helmet>
                      <title>Big Dog Treadmill | Grand Carpet Mill</title>
                      <meta name="description" content="Buy the Big Dog Treadmill for your large dog at Grand Carpet Mill. Robust design for large breeds." />
                    </Helmet>
                    <Products notify={notify} product={productList.BigDog} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/SmallDog"
                render={() => (
                  <>
                    <Helmet>
                      <title>Small Dog Treadmill | Grand Carpet Mill</title>
                      <meta name="description" content="Buy the Small Dog Treadmill for your small dog at Grand Carpet Mill. Perfect for small breeds and puppies." />
                    </Helmet>
                    <Products notify={notify} product={productList.SmallDog} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/WorkingHarness"
                render={() => (
                  <>
                    <Helmet>
                      <title>Working Harness | Grand Carpet Mill</title>
                      <meta name="description" content="Buy the Working Harness for your dog at Grand Carpet Mill. High-quality harnesses for working dogs." />
                    </Helmet>
                    <Products notify={notify} product={productList.WorkingHarness} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/SmallDogReplacementBelts"
                render={() => (
                  <>
                    <Helmet>
                      <title>Small Dog Replacement Belts | Grand Carpet Mill</title>
                      <meta name="description" content="Buy replacement belts for the Small Dog Treadmill at Grand Carpet Mill. Keep your treadmill running smoothly." />
                    </Helmet>
                    <Products notify={notify} product={productList.SmallDogReplacementBelt} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/BigDogReplacementBelts"
                render={() => (
                  <>
                    <Helmet>
                      <title>Big Dog Replacement Belts | Grand Carpet Mill</title>
                      <meta name="description" content="Buy replacement belts for the Big Dog Treadmill at Grand Carpet Mill. Keep your treadmill running smoothly." />
                    </Helmet>
                    <Products notify={notify} product={productList.BigDogReplacementBelt} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/GrandDeluxeReplacementBelts"
                render={() => (
                  <>
                    <Helmet>
                      <title>Grand Deluxe Replacement Belts | Grand Carpet Mill</title>
                      <meta name="description" content="Buy replacement belts for the Grand Deluxe Treadmill at Grand Carpet Mill. Keep your treadmill running smoothly." />
                    </Helmet>
                    <Products notify={notify} product={productList.GrandDeluxeReplacementBelt} cart={cart} setCart={setCart} itemId={itemId} setItemId={setItemId} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route
                path="/Checkout"
                render={() => (
                  <>
                    <Helmet>
                      <title>Checkout | Grand Carpet Mill</title>
                      <meta name="description" content="Complete your purchase at Grand Carpet Mill. Secure and easy checkout process." />
                    </Helmet>
                    <Checkout cart={cart} notify={notify} setCart={setCart} calculateDiscount={calculateDiscount} calculateTotal={calculateTotal} />
                  </>
                )}
              />
              <Route path="/FAQ" component={FAQ} />
              <Route path="/Testimonials" component={Testimonials} />
              <Route path="/MissionStatement" component={MissionStatement} />
              <Route path="/ContactUs" component={ContactUs} />
              <Redirect to="/Home" />
            </Switch>
            <Footer />
          </div>

        </Container>
      </BrowserRouter>
      <div style={{ position: 'fixed', top: '8vh', right: '20px' }}>
        <Toasty toasts={toasts} setToasts={setToasts} />
      </div>
    </Fragment>
  );
};

export default App;
