import React, { useState } from 'react';
import { Col, Row, Card, Button, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import ProductTable from './ProductTable';
import { Divider, useMediaQuery } from '@material-ui/core';
import { productIDs } from '../../../javascript/App';
import { OrderSplash } from '../order/orderSplash';
import { productSize, productSizesMap } from '../../../enums/productSizes';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { getItemTotal } from '../../../javascript/App';

export const Products = ({
  product,
  cart,
  setCart,
  itemId,
  setItemId,
  calculateDiscount,
  calculateTotal
}) => {
  const [show, setShow] = useState(false);
  const isSmall = useMediaQuery('(max-width:800px)');
  const [size, setSize] = useState(productSize.Small);
  const moneyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format;

  const addToCart = (productToAdd, sizeId) => {
    if (!productToAdd) {
      productToAdd = { ...product };
    }
    if (!sizeId) {
      sizeId = size;
    }
    if (product) {
      const cartClone = { ...cart };
      let prevProduct = cartClone.Items.find(
        x => x.ProductID === productToAdd.ProductID && x.SizeID === sizeId
      );
      if (prevProduct) {
        const items = cart.Items.map(x => {
          if (x.ProductID === productToAdd.ProductID && x.SizeID === sizeId) {
            x.Quantity += 1;
          }
          return x;
        });
        cartClone.Items = items;
        cartClone.Discount = calculateDiscount(items);
        cartClone.Total = calculateTotal(items);
        setCart(cartClone);
      } else {
        const newItems = [...cartClone.Items];
        const cartItemDTO = {
          Name: productToAdd.name,
          Description: productToAdd.description,
          Price: productToAdd.price,
          Shipping: productToAdd.shipping,
          Handling: productToAdd.handling,
          Quantity: productToAdd.quantity,
          ItemID: itemId,
          ProductID: productToAdd.ProductID,
          IsTreadmill: productToAdd.isTreadmill,
          SizeID: sizeId
        };
        setItemId(itemId + 1);
        newItems.push(cartItemDTO);
        const discount = calculateDiscount(newItems);
        cartClone.Discount = discount;
        cartClone.Items = newItems;
        cartClone.Total = calculateTotal(newItems);
        setCart(cartClone);
      }
      toast.success(`${productToAdd.name} was added to your order!`);
      if (productToAdd.isTreadmill) {
        setShow(true);
      }
    } else {
      toast.error(
        'There was a problem with your order. Please contact us if the problem persists.'
      );
    }
  };

  const structuredData = {
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": product.name,
    "image": product.image,
    "description": product.description,
    "sku": product.ProductID,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": getItemTotal(product.price, 1, product.shipping, product.handling),
      "availability": "http://schema.org/InStock",
      "url": window.location.href
    }
  };

  return (
    product && (
      <div id="products" className="ht-100">
        <Helmet>
          <title>{product.name} | Grand Carpet Mill</title>
          <meta
            name="description"
            content={`Buy the ${product.name} at Grand Carpet Mill. ${product.description}`}
          />
          <meta
            name="keywords"
            content={`${product.name}, Grand Carpet Mill, dog treadmills, pet fitness, ${product.name} details`}
          />
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>

        <ToastContainer
          style={{ marginTop: '5rem' }}
          autoClose={3000}
          draggable={false}
          position="top-right"
        />

        <Card className="mb-3 products" style={{ minHeight: '85vh' }}>
          <h5 className="card-header card-title pl-5">{product.name}</h5>
          <Row className="no-gutters">
            {/* Product image */}
            <Col
              md="6"
              sm="12"
              style={{ marginTop: '1rem' }}
              className="p-3 text-center"
            >
              <img
                className={
                  product.isTreadmill
                    ? 'card-img img-product img-thumbnail'
                    : 'card-img img-thumbnail img-product-sm'
                }
                src={product.image}
                alt={`${product.name}`}
                id="product"
              />
              <p className="text-muted">All shipping prices are for the Contiguous U.S.</p>
            </Col>

            {/* Product Details */}
            <Col md="6" sm="12">
              <div className="card-body">
                <h4 className="card-title text-product-title">{product.name}</h4>
                <p
                  style={{ fontSize: '1.25rem', paddingTop: '1rem', paddingBottom: '1rem' }}
                  className="card-text m-0"
                >
                  <span style={{ fontSize: '1.25rem', marginRight: '1rem' }}>
                    <u>Description:</u>
                  </span>
                  {product.description}
                </p>

                {/* Treadmill specs table */}
                {product.isTreadmill && (
                  <ProductTable
                    price={product.price}
                    height={product.height}
                    width={product.width}
                    weight={product.weight}
                    beltLength={product.beltLength}
                    beltWidth={product.beltWidth}
                  />
                )}

                {/* Harness Size Selection */}
                {product.ProductID === productIDs.WorkingHarness && (
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label style={{ marginRight: '.5rem', marginTop: '.5rem' }}>
                        Size
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={size}
                        style={{ width: '8rem' }}
                        onChange={e => setSize(e.target.value)}
                      >
                        <option value={productSize.Small}>
                          {productSizesMap[productSize.Small]}
                        </option>
                        <option value={productSize.Medium}>
                          {productSizesMap[productSize.Medium]}
                        </option>
                        <option value={productSize.Large}>
                          {productSizesMap[productSize.Large]}
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}

                {/* Pricing + Buttons  */}
                <Row className="mt-4" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                    <Card className="shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between mb-2">
                          <strong className='mr-2'>Price:</strong>
                          <span>{moneyFormat(product.price)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <strong className='mr-2'>Shipping:</strong>
                          <span>{moneyFormat(product.shipping)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <strong className='mr-2'>Handling:</strong>
                          <span>{moneyFormat(product.handling)}</span>
                        </div>

                        <Divider className="my-3" />

                        <div className="d-flex justify-content-end mb-4">
                          <strong className='mr-2'>Total:</strong>
                          <span>
                            {moneyFormat(
                              getItemTotal(
                                product.price,
                                1,
                                product.shipping,
                                product.handling
                              )
                            )}
                          </span>
                        </div>

                        {/* Buttons at bottom of the same card */}
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="primary"
                            className="mr-2"
                            style={{ minWidth: '8rem' }}
                            onClick={() =>
                              product.ProductID === productIDs.WorkingHarness
                                ? addToCart(product, size)
                                : addToCart(product)
                            }
                          >
                            Add To Cart
                          </Button>
                          <LinkContainer to="Checkout">
                            <Button
                              variant="warning"
                              style={{ minWidth: '8rem' }}
                            >
                              Checkout
                            </Button>
                          </LinkContainer>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {product.isTreadmill && (
                  <Row className="mt-3">
                    <Col>
                      <small className="text-muted">
                        Harness sold separately. Bundle it with your treadmill to receive a discounted rate!
                      </small>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Card>

        <OrderSplash show={show} setShow={setShow} addToCart={addToCart} />
      </div>
    )
  );
};
