import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import harnessPicture from '../../../images/Products/harness.jpg';
import { productSize, productSizesMap } from '../../../enums/productSizes';
import { getProductList } from '../../../javascript/App';
import { useMediaQuery } from '@material-ui/core';
import { Helmet } from 'react-helmet';

export const OrderSplash = ({ show, setShow, addToCart }) => {
  const [size, setSize] = useState(productSize.Small);
  const workingHarness = getProductList().WorkingHarness;
  const harnessDiscount = 6; // Bundling saves 6 dollars.
  const isSmall = useMediaQuery('(max-width:800px)');
  const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

  return (
    <div style={{ fontSize: isSmall ? '70%' : '100%' }}>
      <Helmet>
        <title>Add a 5-Point Harness | Grand Carpet Mill</title>
        <meta name="description" content="Add a 5-point harness to your order at a discounted rate when bundled with your treadmill." />
      </Helmet>
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" size={isSmall ? "sm" : "lg"}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: isSmall ? '1rem' : '1.5rem' }}>Add a 5-point harness to your order?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: 'auto' }}>
            <p style={{ fontSize: isSmall ? '1rem' : '1.25rem' }}>
              Would you like to add a 5-point harness to your order?
              Bundling it with your treadmill will give you a discounted rate!
            </p>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={harnessPicture}
                  alt="5-point harness for dogs"
                  style={{ width: isSmall ? '13rem' : 'auto' }}
                />
              </div>
              <div className="text-center pt-2">
                <del className="text-strikethrough">
                  Was: {moneyFormat(workingHarness.price)}
                </del>
                <p className="text-danger mx-3">
                  Now: {moneyFormat(workingHarness.price - harnessDiscount)}
                  <span style={{ marginLeft: '0.5rem' }}>
                    + ({moneyFormat(workingHarness.handling)} handling fee)
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Form.Group as={Row} controlId="formHarnessSize">
            <Form.Label column sm="auto" style={{ marginRight: '.5rem', marginTop: '.5rem' }}>Size</Form.Label>
            <Col sm="auto">
              <Form.Control
                as="select"
                value={size}
                style={{ width: '8rem' }}
                onChange={(e) => setSize(e.target.value)}
              >
                <option value={productSize.Small}>{productSizesMap[productSize.Small]}</option>
                <option value={productSize.Medium}>{productSizesMap[productSize.Medium]}</option>
                <option value={productSize.Large}>{productSizesMap[productSize.Large]}</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => { addToCart(workingHarness, size); setShow(false); }}>
            Add to Cart
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}